import styled from "styled-components";

export const XydcjStyle = styled.section`
    position: fixed;
    bottom: 1.30rem;
    right: 0.06rem;
    z-index: 2;
    img {
        width: 1.5rem;
    }
`;
